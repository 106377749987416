import React from "react"
import SEO from "../../components/seo"
import LayoutsComponent from '../../layouts/LayoutsComponent';
import ComoFunciona from '../../components/pages/footer/ComoFunciona'

const ComoFuncionaPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    pageContext={pageContext}
    location={location}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <ComoFunciona companyName={pageContext.company} />
      </>
    )}
  />
)

export default ComoFuncionaPage